import qs from "qs";
import Error from "@/core/services/Error";
import { request } from "@/core/services/Request";
import _ from "lodash";

const HALL_SLOTS_STATE = {
  token: null,
  id: null,
  slot: null,
  start_time: null,
  end_time: null,
  status: false,
  error: false,
};

const DEFAULT_FORM_STATE = {
  name: null,
  rent: null,
  order: null,
  max_capacity: null,
  min_capacity: null,
  s_gst: null,
  c_gst: null,
  user_id: [],
  _method: 'post',
};

const DEFAULT_FILTER_STATE = {
  name: null,
};

const COLUMN_DEFINATION = () => [
  {
    text: "#",
    sortable: false,
    value: 'id',
  },
  {
    text: "Name",
    sortable: false,
    value: 'name',
  },
  {
    text: "Hall Slots",
    sortable: false,
    value: 'hallSlots',
  },
  {
    text: "Capacity",
    sortable: false,
    value: 'max_capacity',
  },
  {
    text: "Action",
    value: "action",
    sortable: false,
  },

];


export default {
  data() {
    return {
      operationTitle: 'Halls',
      formFields: { ...DEFAULT_FORM_STATE },
      formErrors: new Error({}),
      filters: { ...DEFAULT_FILTER_STATE },
      listUrl: '/halls',
      columns: COLUMN_DEFINATION(this),
      hall_slot: { ...HALL_SLOTS_STATE },
      hall_slots: [],
      dropdowns: {
        subUsers: [],
        countries: [],
        states: [],
        fromTime: [
          '00:00',
          '01:00',
          '01:30',
          '02:00',
          '02:30',
          '03:00',
          '03:30',
          '04:00',
          '04:30',
          '05:00',
          '05:30',
          '06:00',
          '06:30',
          '07:00',
          '07:30',
          '08:00',
          '08:30',
          '09:00',
          '09:30',
          '10:00',
          '10:30',
          '11:00',
          '11:30',
          '12:00',
          '12:30',
          '13:00',
          '13:30',
          '14:00',
          '14:30',
          '15:00',
          '15:30',
          '16:00',
          '16:30',
          '17:00',
          '17:30',
          '18:00',
          '18:30',
          '19:00',
          '19:30',
          '20:00',
          '20:30',
          '21:00',
          '21:30',
          '22:00',
          '22:30',
          '23:00',
          '23:30',
        ],
        toTime: [
          '00:00',
          '01:00',
          '01:30',
          '02:00',
          '02:30',
          '03:00',
          '03:30',
          '04:00',
          '04:30',
          '05:00',
          '05:30',
          '06:00',
          '06:30',
          '07:00',
          '07:30',
          '08:00',
          '08:30',
          '09:00',
          '09:30',
          '10:00',
          '10:30',
          '11:00',
          '11:30',
          '12:00',
          '12:30',
          '13:00',
          '13:30',
          '14:00',
          '14:30',
          '15:00',
          '15:30',
          '16:00',
          '16:30',
          '17:00',
          '17:30',
          '18:00',
          '18:30',
          '19:00',
          '19:30',
          '20:00',
          '20:30',
          '21:00',
          '21:30',
          '22:00',
          '22:30',
          '23:00',
          '23:30',
        ],
      },
      hallSlot: [],
    };
  },
  methods: {
    handleHollSlots(item) {
      this.hallSlot = item;
    },
    async handleSubmitOperation() {
      this.formErrors = new Error({});
      try {
        const response = await request({
          url: this.formFields.id ? 'halls/update' : 'halls/create',
          method: 'post',
          data: {
            ...this.formFields,
            hall_slots: this.hall_slots,
          },
        });
        if ( this.formFields.id ) {
          this.$global.itemUpdated();
        } else {
          this.$global.itemAdded();
        }
        this.handleOperationClose();
      } catch (error) {
        if ( error.request && error.request.status && error.request.status === 422 ) {
          this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
          return false;
        }
        this.$global.handleServerError(error);
      }

    },
    async handleDeleteOperation(id) {
      let deleteResponse = await this.$root.$confirm('Delete Data Confirmation', "Are you sure you want to delete this ?", {
        primaryBtnText: 'Yes',
        secondaryBtnText: 'No'
      });
      if ( deleteResponse ) {
        try {
          const response = await request({
            method: 'post',
            url: '/halls/delete',
            data: {
              id: id,
            },
          });
          this.loadList(this.listQueryParams);
          this.$global.itemDeleted();
        } catch (errors) {
          this.$global.handleServerError(errors);
        }
      }
    },
    async handleEditOperation(id) {
      try {
        const response = await request({
          method: 'get',
          url: `/halls/detail/${ id }`,
        });
        const { data } = response.data;
        const { hallSlots } = data;
        this.formFields = {
          ...data,
          user_id: data.hall_users ? data.hall_users.map(i => i.id) : [],
        };

        this.hall_slots = hallSlots;
      } catch (e) {
        this.$global.itemEditFails();
        this.formFields = { ...DEFAULT_FORM_STATE };
      }
    },
    async getCountry() {
      try {
        const response = await request({
          url: '/dropdowns/countries',
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.countries = data.map((item) => {
          return {
            value: item.id,
            text: item.name,
          };
        });
      } catch (e) {

      }
    },
    async getState() {
      try {
        const response = await request({
          url: '/dropdowns/states',
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.states = data.map((item) => {
          return {
            value: item.id,
            text: item.name,
            country_id: item.country_id,
          };
        });
      } catch (e) {

      }
    },
    async getSubUsers() {
      try {
        const response = await request({
          url: `/dropdowns/sub/user`,
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.subUsers = data.map((item) => {
          return {
            id: item.id,
            label: item.name,
          };
        });
      } catch (e) {

      }
    },
    handleResetFilterClick() {
      this.filters = { ...DEFAULT_FILTER_STATE };
      this.isFilterApplied = 'reset';
      this.checkFilterCountStatus();
    },
    handleAddUpdateSlotFeatureClick() {
      this.hall_slot.error = false;
      this.formErrors = new Error({});
      let errors = {};

      if ( !this.hall_slot.slot ) {
        this.hall_slot.error = true;
        errors.slot = ['The field is required.'];
      }
      if ( !this.hall_slot.start_time ) {
        this.hall_slot.error = true;
        errors.start_time = ['The field is required.'];
      }
      if ( !this.hall_slot.end_time ) {
        this.hall_slot.error = true;
        errors.end_time = ['The field is required.'];
      }

      _.map(this.hall_slots, (feature) => {
        if ( feature.token !== this.hall_slot.token ) {
          if ( ( feature.slot === this.hall_slot.slot ) ) {
            errors.slot = ['Duplicate entry'];
            this.hall_slot.error = true;
            this.formErrors = new Error(errors);
            return false;
          }
        }
      });

      this.formErrors = new Error(errors);
      if ( this.hall_slot.error ) {
        return false;
      }

      const entity = this.hall_slot;
      let index = -1;
      if ( entity.token ) {
        index = _.findIndex(this.hall_slots, { token: entity.token });
      }

      if ( this.hall_slots[index] !== undefined ) {
        this.hall_slots[index] = entity;
      } else {
        entity.token = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        this.hall_slots.push(entity);
      }

      this.hall_slot = {
        ...HALL_SLOTS_STATE,
        token: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
      };
    },
    async handleDeleteSlotFeatureClick(slots) {
      const index = _.findIndex(this.hall_slots, { token: slots.token });
      if ( index !== -1 ) {
        try {
          const response = await request({
            url: '/halls/slot/delete',
            method: 'post',
            data: {
              hall_id: slots.hall_id,
              slot_id: slots.id
            },
          });

          this.handleDeleteSlotsByIndex(index);

        } catch (error) {
          if ( error.request && error.request.status && error.request.status === 422 ) {
            this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
            return false;
          }
          this.$global.handleServerError(error);
        }
      } else {
        this.handleDeleteSlotsByIndex(index);
      }
    },
    handleDeleteSlotsByIndex(index) {
      if ( this.hall_slots[index] !== undefined ) {
        this.hall_slots.splice(index, 1);
      }
    },
    handleEditSlotFeatureClick(token) {
      const index = _.findIndex(this.hall_slots, { token: token });

      if ( this.hall_slots[index] !== undefined ) {
        this.hall_slots[index] = {
          ...this.hall_slots[index],
        };

        this.hall_slot = { ...this.hall_slots[index] };
      }
    },
    resetSlotFeature() {
      this.hall_slot = { ...HALL_SLOTS_STATE };
    },
    handleOperationClose() {
      this.formFields = { ...DEFAULT_FORM_STATE };
      this.$router.push({ name: "hallList" });
    },
  },
};
